import initialState from '../store/initialState';
import { default as bookingObject } from '../store/booking';
import {
  helperIsANumber,
  helperIsAnInteger,
  helperDeepCopy,
} from '../helpers/index';

const initialBookingObject = helperDeepCopy(bookingObject);

export default function rootReducer(state = initialState, action) {
  let newState = null;
  if (action.type !== 'SET_BOOKABLE_TO_EDIT') {
    newState = Object.assign({}, state);
  }
  switch (action.type) {
    case 'SET_MEDIA_BREAKPOINT':
      newState.mediaBreakpoint = action.payload.mediaBreakpoint;
      return newState;
    case 'SET_IS_PWA_INSTALLED':
      newState.isPwaInstalled = action.payload.flag;
      return newState;
    case 'SET_IS_DASHBOARD_MENU_PRESENT':
      newState.isDashboardMenuPresent = action.payload.flag;
      return newState;
    case 'SET_PAGE_TITLE':
      newState.pageTitle = action.payload.pageTitle;
      newState.hidePageTitle = action.payload.hidePageTitle;
      if (! newState.pageTitle) {
        newState.hidePageTitle = false;
      }
      return newState;
    case 'SET_LANG':
      newState.lang = action.payload.lang;
      return newState;
    case 'SET_FORM_FLASHES':
      newState.formFlashes = action.payload;
      return newState;
    case 'SET_FORM_ERRORS':
      newState.formErrors = action.payload;
      return newState;
    case 'LOGGED_IN':
      newState.loggedIn = true;
      newState.authUser = action.payload;
      window.localStorage.setItem('auth-user', JSON.stringify(newState.authUser));
      return newState;
    case 'SET_AUTH_USER':
      newState.authUser = action.payload;
      window.localStorage.setItem('auth-user', JSON.stringify(newState.authUser));
      return newState;
    case 'LOGGED_OUT':
      newState.loggedIn = false;
      newState.authUser = null;
      window.localStorage.setItem('auth-user', newState.authUser);
      return newState;
    case 'REGISTERED':
      newState.loggedIn = true;
      newState.authUser = action.payload;
      window.localStorage.setItem('auth-user', JSON.stringify(newState.authUser));
      return newState;
    case 'HIDE_REGISTRATION_SUCCESS_ALERT':
      newState.authUser.justRegistered = false;
      return newState;
    case 'RESET_LINK_SENT':
      newState.passwordResetLinkSentText = action.payload;
      return newState;
    case 'HIDE_RESET_LINK_SENT_SUCCESS_ALERT':
      newState.passwordResetLinkSentText = null;
      return newState;
    case 'PASSWORD_RESET':
      newState.passwordResetText = action.payload;
      return newState;
    case 'HIDE_RESET_SUCCESS_ALERT':
      newState.passwordResetText = null;
      return newState;
    case 'EMAIL_VERIFIED':
      newState.emailJustVerified = true;
      return newState;
    case 'HIDE_EMAIL_VERIFIED_SUCCESS_ALERT':
      newState.emailJustVerified = false;
      return newState;
    case 'SET_BUSINESS_ENTITIES':
      newState.businessEntities.entities = action.payload.entities;
      return newState;
    case 'SET_LOCATIONS':
      newState.locations = action.payload.locations;
      return newState;
    case 'SET_ORGANIZATION_TYPES_LEGAL_FORMS':
      newState.businessEntities.organizationTypes = action.payload.organizationTypes;
      newState.businessEntities.organizationalAndLegalForms = action.payload.organizationalAndLegalForms;
      return newState;
    case 'SET_ENTITY_TO_BE_EDITED':
      newState.businessEntities.entityToBeEdited = action.payload.entityToBeEdited;
      return newState;
    case 'SET_SERVICE_CATEGORIES':
      newState.businessEntities.serviceCategories = action.payload.serviceCategories;
      return newState;
    case 'SET_ORGANIZATION_SETTINGS_DATA':
      newState.businessEntities.bookingChannels = action.payload.bookingChannels;
      newState.businessEntities.notificationChannels = action.payload.notificationChannels;
      newState.businessEntities.min_time_till_event = action.payload.min_time_till_event;
      newState.businessEntities.max_time_till_event = action.payload.max_time_till_event;
      newState.businessEntities.minimum_allowed_time_duration = action.payload.minimum_allowed_time_duration;
      return newState;
    case 'SET_ORGANIZATION_SERVICE_PROVIDERS':
      newState.businessEntities.serviceProviders = action.payload.serviceProviders;
      return newState;
    case 'SET_SERVICE_CATEGORIES_WITH_COUNT':
      newState.serviceCategoriesWithCount = action.payload.serviceCategoriesWithCount;
      return newState;
    case 'SET_ORGANIZATIONS_LIST':
      newState.organizations = action.payload.organizations;
      return newState;
    case 'SET_ADMINISTRATORS':
      newState.administrators = action.payload.administrators;
      return newState;
    case 'SET_CHIEFS_ORGANIZATIONS':
      newState.chiefsOrganizations = action.payload.chiefsOrganizations;
      return newState;
    case 'SET_ADMINISTRATOR_TO_BE_EDITED':
      newState.administratorToBeEdited = action.payload.administratorToBeEdited;
      return newState;
    case 'SET_SERVICE_PROVIDERS':
      newState.serviceProviders = action.payload.serviceProviders;
      return newState;
    case 'SET_SERVICE_PROVIDER_TO_BE_EDITED':
      newState.serviceProviderToBeEdited = action.payload.serviceProviderToBeEdited;
      return newState;
    case 'SET_BOOKABLE_ATTRIBUTE':
      if (action.payload.attrLocale) {
        newState.bookable[action.payload.attrName][action.payload.attrLocale] = action.payload.attrValue;
      } else {
        newState.bookable[action.payload.attrName] = action.payload.attrValue;
      }
      return newState;
    case 'SET_ORGANIZATION_SERVICE_RELATED_DATA':
      newState.organizationServiceRelatedData = action.payload.organizationServiceRelatedData;
      return newState;
    case 'RESET_BOOKABLE':
      newState.bookable = action.payload.bookable;
      return newState;
    case 'SET_BOOKABLES':
      newState.bookables = action.payload.bookables;
      return newState;
    case 'SET_BOOKABLE_TO_EDIT':
      newState = helperDeepCopy(state);
      newState.bookable.id = action.payload.bookable.id;
      newState.bookable.thumbnail_data = action.payload.bookable.thumbnail;
      newState.bookable.branches = action.payload.bookable.branches.map(item => item.id);
      if (newState.bookable.branches.length > 0) {
        newState.bookable.branch = newState.bookable.branches[0];
      }
      newState.bookable.subtype = action.payload.bookable.type.subtype;
      newState.bookable.room_type = action.payload.bookable.type.room_type;
      action.payload.bookable.translations.forEach((translation) => {
        newState.bookable.title[translation.locale] = translation.title;
        newState.bookable.description[translation.locale] = translation.description;
      });
      newState.bookable.display_on_booking_page = action.payload.bookable.display_on_booking_page;
      newState.bookable.hide_duration_on_booking_page = action.payload.bookable.type.hide_duration_on_booking_page;
      newState.bookable.service_category = action.payload.bookable.service_category_id;
      newState.bookable.regular_price = action.payload.bookable.regular_price;
      newState.bookable.sale_percentage = action.payload.bookable.sale_percentage;
      newState.bookable.sale_price = action.payload.bookable.sale_price;
      newState.bookable.duration_hours = action.payload.bookable.type.duration_hours;
      newState.bookable.duration_minutes = action.payload.bookable.type.duration_minutes;
      newState.bookable.buffer_hours_after = action.payload.bookable.type.buffer_hours_after;
      newState.bookable.buffer_minutes_after = action.payload.bookable.type.buffer_minutes_after;
      newState.bookable.max_num_clients = action.payload.bookable.type.max_num_clients;
      newState.bookable.sku = action.payload.bookable.type.sku;
      newState.bookable.type_of_tariffication = action.payload.bookable.type.type_of_tariffication;
      newState.bookable.event_datetime_from = action.payload.bookable.type.datetime_from;
      newState.bookable.event_datetime_to = action.payload.bookable.type.datetime_to;
      return newState;
    case 'REMOVE_THUMBNAIL':
      newState = helperDeepCopy(state);
      newState.bookable.thumbnail_data = {};
      return newState;
    case 'SET_SHOPFRONT_ENTITY_BY_ID':
      const organizations = newState.organizations.data.filter(a => parseInt(a.id) === parseInt(action.payload.id));
      newState.shopfrontEntity = organizations.length ? organizations[0] : null;
      return newState;
    case 'SET_SHOPFRONT_ENTITY':
      newState.shopfrontEntity = action.payload.organization;
      return newState;
    case 'MERGE_INTO_SHOPFRONT_ENTITY':
      switch (action.payload.relationName) {
        case 'branches':
          newState.shopfrontEntity.branches = action.payload.data;
          break;
        case 'serviceProviders':
          newState.shopfrontEntity.service_providers = action.payload.data;
          break;
        case 'services':
          newState.shopfrontEntity.services = action.payload.data;
          break;
        case 'rentables':
          newState.shopfrontEntity.rentables = action.payload.data;
          break;
        case 'reservables':
          newState.shopfrontEntity.reservables = action.payload.data;
          break;
        default:
          break;
      }
      return newState;
    case 'SET_BOOKING_TYPE':
      newState.booking.type = action.payload.type;
      return newState;
    case 'SET_BOOKING_BRANCH':
      newState.booking.branch = action.payload.branch;
      newState.booking.reservables = [];
      newState.booking.rentables = [];
      newState.booking.nextStepAvailable = true;
      return newState;
    case 'MOVE_TO_NEXT_STEP':
      newState.booking.currentStep = newState.bookingSteps[newState.booking.type][newState.booking.currentStep].nextStep;
      newState.bookingSteps[newState.booking.type][newState.booking.currentStep].passed = true;
      switch (newState.booking.currentStep) {
        case 'branch':
          if (newState.booking.branch) {
            newState.booking.nextStepAvailable = true;
          } else {
            newState.booking.nextStepAvailable = false;
          }
          break;
        case 'service':
          if (newState.booking.service) {
            newState.booking.nextStepAvailable = true;
          } else {
            newState.booking.nextStepAvailable = false;
          }
          break;
        case 'provider':
          if (newState.booking.serviceProvider) {
            newState.booking.nextStepAvailable = true;
          } else {
            newState.booking.nextStepAvailable = false;
          }
          break;
        case 'date':
          if (newState.booking.datetime.from && newState.booking.datetime.to) {
            newState.booking.nextStepAvailable = true;
          } else {
            newState.booking.nextStepAvailable = false;
          }
          break;
        case 'client':
          if (
            newState.booking.client.firstName &&
            newState.booking.client.lastName &&
            newState.booking.client.phone // &&
            // newState.booking.client.cityTown &&
            // newState.booking.client.address
          ) {
            newState.booking.nextStepAvailable = true;
          } else {
            newState.booking.nextStepAvailable = false;
          }
          break;
        default:
          newState.booking.nextStepAvailable = false;
          break;
      }
      return newState;
    case 'MOVE_TO_PREV_STEP':
      if (newState.booking.currentStep === 'date') {
        newState.booking.datetime = {
          from: null,
          to: null,
        };
      }
      newState.bookingSteps[newState.booking.type][newState.booking.currentStep].passed = false;
      newState.booking.currentStep = newState.bookingSteps[newState.booking.type][newState.booking.currentStep].prevStep;
      newState.booking.nextStepAvailable = true;
      return newState;
    case 'RESET_BOOKING_PROCESS':
      const oldState = helperDeepCopy(newState.booking);
      newState.booking = helperDeepCopy(initialBookingObject);
      newState.booking.type = oldState.type;
      // newState.booking.branchServices = oldState.branchServices;
      // newState.booking.branchReservables = oldState.branchReservables;
      // newState.booking.branchRentables = oldState.branchRentables;
      newState.booking.client = oldState.client;
      for (const stepName in newState.bookingSteps[oldState.type]) {
        const step = newState.bookingSteps[oldState.type][stepName];
        if (stepName !== 'branch') {
          step.passed = false;
        }
      }
      newState.acceptedBookingInfo = null;
      return newState;
    case 'SET_BRANCH_SERVICES':
      newState.booking.branchServices[newState.booking.branch.id] = action.payload.services;
      return newState;
    case 'SET_BOOKING_SERVICE':
      newState.booking.service = action.payload.service;
      newState.booking.nextStepAvailable = true;
      return newState;
    case 'SET_BOOKING_SERVICE_PROVIDER':
      newState.booking.serviceProvider = action.payload.serviceProvider;
      newState.booking.nextStepAvailable = true;
      return newState;
    case 'SET_BOOKING_DATETIME':
      newState.booking.datetime.from = action.payload.from;
      newState.booking.datetime.to = action.payload.to;
      if (
        newState.booking.datetime.from
        &&
        newState.booking.datetime.from.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/)
        &&
        newState.booking.datetime.to
        &&
        newState.booking.datetime.to.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/)
      ) {
        newState.booking.nextStepAvailable = true;
      } else {
        newState.booking.nextStepAvailable = false;
      }
      return newState;
    case 'SET_CLIENT_INFO':
      newState.booking.client[action.payload.key] = action.payload.value;
      if (! newState.authUser || (newState.authUser && newState.authUser.role !== 'operator')) {
        if (['adminNotes', 'clientNotes'].indexOf(action.payload.key) === -1) {
          window.localStorage.setItem('client-' + action.payload.key, action.payload.value);
        }
      }
      return newState;
    case 'NECESSARY_CLIENT_FIELDS_FILLED':
      newState.booking.nextStepAvailable = action.payload.flag;
      return newState;
    case 'CONFIRM_BOOKING':
      newState.booking.currentStep = null;
      newState.acceptedBookingInfo = action.payload.acceptedBookingInfo;
      return newState;
    case 'SET_BRANCH_RESERVABLES':
      newState.booking.branchReservables[newState.booking.branch.id] = action.payload.reservables;
      return newState;
    case 'ADD_TO_RESERVABLES':
      const reservable = newState.booking.branchReservables[newState.booking.branch.id].filter(r => parseInt(r.id) === parseInt(action.payload.id))[0];
      newState.booking.reservables.push(reservable);
      if (helperIsANumber(newState.booking.stayDuration) && newState.booking.stayDuration > 0) {
        newState.booking.nextStepAvailable = true;
      }
      return newState;
    case 'REMOVE_FROM_RESERVABLES':
      newState.booking.reservables = newState.booking.reservables.filter(r => parseInt(r.id) !== parseInt(action.payload.id));
      if (newState.booking.reservables.length === 0) {
        newState.booking.nextStepAvailable = false;
      }
      return newState;
    case 'RESET_RESERVABLES':
      newState.booking.reservables = [];
      return newState;
    case 'SET_STAY_DURATION':
      if (
        (
          helperIsAnInteger(action.payload.minutes)
          // ||
          // 0.5 === parseFloat(action.payload.minutes) - Math.floor(action.payload.minutes)
        )
        ||
        action.payload.minutes === ''
      ) {
        newState.booking.stayDuration = action.payload.minutes;
        if (
          (
            helperIsAnInteger(newState.booking.stayDuration)
            // ||
            // 0.5 === parseFloat(newState.booking.stayDuration) - Math.floor(newState.booking.stayDuration)
          )
          &&
          newState.booking.stayDuration > 0
          &&
          newState.booking.reservables.length > 0
        ) {
          newState.booking.nextStepAvailable = true;
        } else {
          newState.booking.nextStepAvailable = false;
        }
      } else {
        newState.booking.nextStepAvailable = false;
      }
      return newState;
    case 'SET_BRANCH_RENTABLES':
      newState.booking.branchRentables[newState.booking.branch.id] = action.payload.rentables;
      return newState;
    case 'ADD_TO_RENTABLES':
      const rentable = newState.booking.branchRentables[newState.booking.branch.id].filter(r => parseInt(r.id) === parseInt(action.payload.id))[0];
      newState.booking.rentables = newState.booking.rentables.filter(r => r.type.type_of_tariffication === rentable.type.type_of_tariffication);
      newState.booking.rentables.push(rentable);
      if (helperIsANumber(newState.booking.rentingDuration) && newState.booking.rentingDuration > 0) {
        newState.booking.nextStepAvailable = true;
      }
      return newState;
    case 'REMOVE_FROM_RENTABLES':
      newState.booking.rentables = newState.booking.rentables.filter(r => parseInt(r.id) !== parseInt(action.payload.id));
      if (newState.booking.rentables.length === 0) {
        newState.booking.nextStepAvailable = false;
      }
      return newState;
    case 'RESET_RENTABLES':
      newState.booking.rentables = [];
      return newState;
    case 'SET_RENTING_DURATION':
      if (
        helperIsAnInteger(action.payload.duration)
        ||
        action.payload.duration === ''
      ) {
        newState.booking.rentingDuration = action.payload.duration;
        if (
          helperIsAnInteger(newState.booking.rentingDuration)
          &&
          newState.booking.rentingDuration > 0
          &&
          newState.booking.rentables.length > 0
        ) {
          newState.booking.nextStepAvailable = true;
        } else {
          newState.booking.nextStepAvailable = false;
        }
      } else {
        newState.booking.nextStepAvailable = false;
      }
      return newState;
    case 'SET_TARIFFICATION':
      newState.booking.tariffication = action.payload.tariffication;
      return newState;
    case 'SET_BRANCHES_LIST':
      newState.branchesList = action.payload.branches.sort(function(a, b) {
        var x = a.title.toLowerCase();
        var y = b.title.toLowerCase();
        if ( x < y ) { return -1; }
        if ( x > y ) { return 1; }
        return 0;
      });
      return newState;
    case 'SET_BOOKINGS_FILTER':
      newState.bookingsFilter = helperDeepCopy(action.payload.filters);
      return newState;
    case 'SELECT_BOOKING_TO_VIEW':
      newState.bookingToView = action.payload.booking;
      return newState;
    case 'REDUCE_NOTIFICATIONS_COUNT':
      newState.authUser.notifications_count -= 1;
      return newState;
    default:
      return state;
  }
}
