import { Fragment } from 'react';
import numeral from 'numeral';
import FlashMessage from '../components/FlashMessage';
import Inputmask from 'inputmask';

export function helperScrollUp(elementId) {
  const elementIdName = (typeof elementId !== 'undefined') ? elementId : "crud-panel";
  let elemObj = window.document.getElementById(elementIdName);
  if (! elemObj) {
    elemObj = window.document.getElementById("header");
  }
  if (! elemObj) {
    return;
  }
  setTimeout(() => {
    window.scrollTo({
      top: window.jQuery(elemObj).offset().top - window.jQuery('.main_menu').height(),
      behavior: 'smooth',
    });
  }, 1);
}

export function helperIsANumber(val) {
  return ! isNaN(Number(val));
}

export function helperIsAnInteger(val) {
  // eslint-disable-next-line
  return helperIsANumber(val) && val == parseInt(val, 10);
}

export function helperCollectFormData(form, names) {
  let formData = {};
  for (const key in form.elements) {
    if (names.includes(form.elements[key].name)) {
      formData[form.elements[key].name] = form.elements[key].value;
    }
  }
  return formData;
}

export function helperDisplayFormErrors(inputFormErrors) {
  return (inputFormErrors) ?
    inputFormErrors.map((msg, idx) => (
      <span key={ idx } className="help-block invalid-feedback d-block">{ msg }</span>
    ))
    :
    null
}

export function helperDisplayFormFlashes(formFlashes) {
  return (formFlashes) ?
    Object.keys(formFlashes).map((flashClass) => {
      return (
        formFlashes[flashClass].map((flashText, idxKey) => (
          <FlashMessage key={ idxKey } msgClass={ flashClass } msgText={ flashText } />
        ))
      );
    })
    :
    null
}

export function helperDoBreak(str) {
  return str ? <br /> : null;
}

// https://stackoverflow.com/questions/18017869/build-tree-array-from-flat-array-in-javascript
export function helperBuildTree(list) {
  if (typeof list === typeof undefined || list === null) {
    return [];
  }
  let listArr = [];
  if (typeof list === 'object') {
    for (const key in list) {
      listArr.push(list[key]);
    }
  } else if (Array.isArray(list)) {
    listArr = list;
  }

  let map = {};
  let roots = [];
  for (let i = 0; i < listArr.length; i++) {
    map[listArr[i].id] = i; // initialize the map
    listArr[i].children = []; // initialize the children
  }

  for (let i = 0; i < listArr.length; i++) {
    const node = listArr[i];
    if (node.parent_id === 0) {
      roots.push(node);
    } else {
      // if you have dangling branches check that map[node.parent_id] exists
      if (typeof map[node.parent_id] === typeof undefined || map[node.parent_id] === null) {
        roots.push(node);
      } else {
        listArr[map[node.parent_id]].children.push(node);
      }
    }
  }
  // if all items are dangling (that do not have parents)
  if (roots.length === 0) {
    return listArr;
  }
  return roots;
}

export function helperTraverseTree(tree, listType, prefix, elems, recursionDepth = 0, maxDepth = 0) {
  if (maxDepth && (recursionDepth === maxDepth)) {
    return;
  }

  if (! elems) {
    // eslint-disable-next-line
    var elems = [];
  }

  for (const key in tree) {
    let optionTitle = '';
    for (let i = 0; i < recursionDepth; i++) {
      if (prefix) {
        optionTitle += prefix + prefix;
      } else {
        optionTitle += '--';
      }
    }
    optionTitle += ' ' + tree[key].title;

    if (listType === 'li') {
      elems.push(
        <li key={ tree[key].id } dangerouslySetInnerHTML={{ __html: optionTitle }} />
      );
    } else {
      elems.push(
        <option key={ tree[key].id } value={ tree[key].id }>
          { optionTitle }
        </option>
      );
    }
    
    if (typeof(tree[key].children) !== 'undefined' && tree[key].children.length) {
      helperTraverseTree(tree[key].children, listType, prefix, elems, recursionDepth + 1, maxDepth);
    }
  }
  return elems;
}

export function helperNullToEmptyString(val) {
  return (val === null) ? '' : val;
}

export function helperGetDefaultLocation(locations) {
  let location = locations[0].id;
  let defaultLocation = locations.filter((obj) => {
    return (obj.keyname === 'toshkent-shahri' || obj.keyname === 'gorod-tashkent' || obj.keyname === 'tashkent-city');
  });
  if (typeof defaultLocation[0] !== typeof undefined) {
    location = defaultLocation[0].id;
  }
  return location;
}

export function helperImageUrl(url, dimensions) {
  const parts = url.split('.');
  if (dimensions && dimensions.w && dimensions.h && parts[0] && parts[1]) {
    url = parts[0] + '-' + dimensions.w + 'x' + dimensions.h + '.' + parts[1];
  }
  let storageSlug = 'storage';
  if (url[0] !== '/') {
    storageSlug += '/';
  }
  return process.env.REACT_APP_FRONTEND_URL + storageSlug + url;
}

export function helperSlotToMinutes(slot) {
  const [hour, minute] = slot.split(':', 2);
  return parseInt(hour, 10) * 60 + parseInt(minute, 10);
}

export function helperMinutesToSlot(minutes, seconds) {
  let slot = '';
  const hour = parseInt(minutes / 60);
  const minute = parseInt(minutes % 60);
  if (hour < 10) {
    slot += '0' + hour;
  } else {
    slot += hour;
  }
  slot += ':';
  if (minute < 10) {
    slot += '0' + minute;
  } else {
    slot += minute;
  }
  return slot + (seconds ? seconds : '');
}

export function helperAddMinutesToSlot(slot, minutesToAdd) {
  let minutes = helperSlotToMinutes(slot);
  minutes += minutesToAdd;
  return helperMinutesToSlot(minutes, slot.substring(5));
}

export function helperGetTimeIntervals(slots, intervalMinute) {
  if (! intervalMinute) {
    intervalMinute = 30;
  }
  let intervals = [];
  let intervalPair = [];
  let thisSlot = null;
  let nextSlot = null;
  let lastDiff = 0;
  for (let i = 0; i < slots.length; i++) {
    if (typeof undefined === typeof intervalPair[0]) {
      thisSlot = slots[i];
      intervalPair[0] = thisSlot;
    }
    nextSlot = slots[i + 1];
    if (typeof undefined === typeof nextSlot && i < slots.length - 1) {
      continue;
    }
    if ((i === slots.length - 1) || (helperSlotToMinutes(nextSlot) - (helperSlotToMinutes(thisSlot) + lastDiff) > intervalMinute)) {
      intervalPair[1] = helperAddMinutesToSlot(slots[i], intervalMinute);
      intervals.push(intervalPair);
      intervalPair = [];
      thisSlot = nextSlot = null;
      lastDiff = 0;
    } else {
      lastDiff += intervalMinute;
    }
  }
  return intervals;
}

export function helperNl2br(text) {
  if (text === null) {
    return text;
  }
  return text.split('\n').map((item, key) => {
    return <Fragment key={ key }>{ item }<br/></Fragment>
  });
}

export function helperFieldHasError(errors, key, errorClass) {
  return (typeof undefined !== typeof errors && errors != null && errors[key] != null && errors[key][0] != null) ? errorClass : '';
}

export function helperMapOrganizationServiceCategories(serviceCategories, bookableType) {
  if (bookableType === 'service') {
    return serviceCategories.filter(cat => cat.booking_type === 'appointments');
  } else if (bookableType === 'reservable') {
    return serviceCategories.filter(cat => cat.booking_type === 'reservations');
  } else if (bookableType === 'rentable') {
    return serviceCategories.filter(cat => cat.booking_type === 'rentals');
  } else if (bookableType === 'event' || bookableType === 'venue') {
    return serviceCategories.filter(cat => cat.booking_type === 'events');
  }
  return [];
}

export function helperFormatPrice(priceStr) {
  return numeral(priceStr)
    .format('0,0.00')
    .replace(/,/g, "\u00a0")
    .replace('.00', '');
}

export function helperHasExpired(datetime) {
  return window.moment(datetime).isBefore(window.moment());
}

/**
 * @link https://www.w3schools.com/howto/howto_js_copy_clipboard.asp
 * @param String elementId
 * @return void
 */
export function helperHighlightSelectText(elementId) {
  var copyText = window.document.getElementById(elementId);
  copyText.select();
  copyText.setSelectionRange(0, 99999); // for mobile devices
  window.navigator.clipboard.writeText(copyText.value);
  return true;
}

/**
 * @param  String datetime  Format: "YYYY-MM-DD 00:00:00"
 * @param  String dayOfWeek Week day name: monday, tuesday, etc.
 * @return Array            Format: ["YYYY-MM-DD", ...]
 */
export function helperGetDatesOfWeekDayWithinMonth(datetime, dayOfWeek) {
  let dayOfWeekDateObject = window.moment(datetime).startOf('month').day(dayOfWeek);
  /**
   * If month is the previous month, then we add at least
   * 7 days to be guaranteed to be in the current month.
   */
  if (dayOfWeekDateObject.month() < window.moment(datetime).month()) {
    dayOfWeekDateObject.add(7, 'd');
  }
  let datesOfWeekDay = [];
  const month = dayOfWeekDateObject.month();
  while (month === dayOfWeekDateObject.month()) {
    datesOfWeekDay.push(
      window.moment(dayOfWeekDateObject).format('YYYY-MM-DD')
    );
    dayOfWeekDateObject.add(7, 'd');
  }
  return datesOfWeekDay;
}

export function helperFormatDatetime(datetimetz, format) {
  return window.moment.utc(datetimetz).format(format);
}

/**
  const intervalsObjWeekDays = [
    {
      week_day: 'monday',
      from: '21:00:00',
      to: '24:00:00',
    },
    {
      week_day: 'tuesday',
      from: '00:00:00',
      to: '08:00:00',
    },
    {
      week_day: 'tuesday',
      from: '21:00:00',
      to: '24:00:00',
    },
    {
      week_day: 'thursday',
      from: '09:00:00',
      to: '13:00:00',
    },
  ];
  const intervalsObjSingleDay = [
    {
      from: '00:00:00',
      to: '08:00:00',
    },
    {
      from: '11:00:00',
      to: '15:00:00',
    },
    {
      from: '21:00:00',
      to: '24:00:00',
    },
  ];
  const intervalsObjDatetimes = [
    {
      from: '2022-10-15 14:00:00',
      to: '2022-10-16 24:00:00',
    },
  ];
*/
export function helperTimeIntervalsToSlots(intervalsArr, minuteStep) {
  if (! minuteStep) {
    console.error('please, indicate minute step');
  }
  const intervals = helperDeepCopy(intervalsArr);
  let slots = {};
  if (intervals.constructor !== Array || (intervals.constructor === Array && intervals.length === 0)) {
    return slots;
  }
  if (typeof undefined !== typeof intervals[0].week_day) {
    slots = {
      monday: {minutes: [], hours: []},
      tuesday: {minutes: [], hours: []},
      wednesday: {minutes: [], hours: []},
      thursday: {minutes: [], hours: []},
      friday: {minutes: [], hours: []},
      saturday: {minutes: [], hours: []},
      sunday: {minutes: [], hours: []},
    };
    intervals.forEach(interval => {
      while (interval.from < interval.to) {
        const minute = interval.from.substring(0, 5);
        const hour = interval.from.substring(0, 2);
        if (slots[interval.week_day].minutes.indexOf(minute) === -1) {
          slots[interval.week_day].minutes.push(minute);
        }
        if (slots[interval.week_day].hours.indexOf(hour) === -1) {
          slots[interval.week_day].hours.push(hour);
        }
        interval.from = helperAddMinutesToSlot(interval.from, minuteStep);
      }
    });
  } else if (intervals[0].from.match(/\d{4}-\d{2}-\d{2} \d{2}:\d{2}(:\d{2})?/)) {
    intervals.forEach(interval => {
      while (window.moment(interval.from).isBefore(window.moment(interval.to))) {
        const theDate = window.moment(interval.from).format('YYYY-MM-DD');
        if (! slots[theDate]) {
          slots[theDate] = {
            minutes: [],
            hours: [],
          };
        }
        const minute = window.moment(interval.from).format('HH:mm');
        if (slots[theDate].minutes.indexOf(minute) === -1) {
          slots[theDate].minutes.push(minute);
        }
        const hour = window.moment(interval.from).format('HH');
        if (slots[theDate].hours.indexOf(hour) === -1) {
          slots[theDate].hours.push(hour);
        }
        interval.from = window.moment(interval.from).add(minuteStep, 'm').format('YYYY-MM-DD HH:mm:00');
      }
    });
  } else {
    slots = {
      minutes: [],
      hours: [],
    };
    intervals.forEach(interval => {
      while (interval.from < interval.to) {
        const minute = interval.from.substring(0, 5);
        const hour = interval.from.substring(0, 2);
        if (slots.minutes.indexOf(minute) === -1) {
          slots.minutes.push(minute);
        }
        if (slots.hours.indexOf(hour) === -1) {
          slots.hours.push(hour);
        }
        interval.from = helperAddMinutesToSlot(interval.from, minuteStep);
      }
    });
  }
  return slots;
}

export function helperTranslation(entity, locale) {
  return entity.translations.filter(e => e.locale === locale)[0];
}

export function helperDeepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function helperInitInputmaskFields() {
  (new Inputmask({
    'mask': '+\\9\\98999999999',
    'placeholder': '#',
  })).mask(window.document.getElementById('phone'));

  (new Inputmask({
    'alias': 'email',
  })).mask(window.document.getElementById('email'));
}

export function helperDestroyInputmaskFields() {
  for (const elementIdName in ['phone', 'email']) {
    const input = window.document.getElementById(elementIdName);
    if (input && input.inputmask) {
      input.inputmask.remove()
    }
  }
}

export function helperRegExpPattern(field) {
  switch (field) {
    case 'phone':
      return RegExp(/\+998\d{9}/);
    case 'email':
      return RegExp(/\S+@\S+\.\S+/);
    default:
      return null;
  }
}

export function helperToggleFooterSticky() {
  const $window = window.jQuery(window);
  const $root = window.jQuery('#root');
  const $footer = window.jQuery('footer');
  if ($root.height() < $window.height()) {
    $footer.css('position', 'relative');
    $footer.css('bottom', (-($window.height() - $root.height())) + 'px');
  } else {
    $footer.css('position', '');
    $footer.css('bottom', '');
  }
}

export function helperIntervalToDateInvervals({start, end}) {
  const intervals = {};
  const startDate = window.moment(start).format('YYYY-MM-DD');
  const endDate = window.moment(end).format('YYYY-MM-DD');
  const startMinute = window.moment(start).format('HH:mm:00');
  const endMinute = window.moment(end).format('HH:mm:00');
  let date = null;
  do {
    date = window.moment(start).format('YYYY-MM-DD');
    const from = date === startDate ? startMinute : '00:00:00';
    const to = date === endDate ? endMinute : '24:00:00';
    if (from < to) {
      intervals[date] = {
        from,
        to,
      };
    }
    start = window.moment(start).add(1, 'd').format('YYYY-MM-DD HH:mm:00');
  } while (date < endDate);
  return intervals;
}

export function helperMaskPhoneField(elementIdName) {
  (new Inputmask({
    'mask': '\\9\\98999999999',
    'placeholder': '#',
  })).mask(window.document.getElementById(elementIdName));
}

export function helperUnmaskPhoneField(elementIdName) {
  const input = window.document.getElementById(elementIdName);
  if (input && input.inputmask) {
    input.inputmask.remove()
  }
}

// https://www.w3resource.com/javascript-exercises/javascript-math-exercise-23.php
export function helperCreateUuid() {
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : (r & (0x3|0x8))).toString(16);
  });
  return uuid;
}

export function helperShowProgressSpinner() {
  window.document.getElementById('loader').style.display = 'block';
}

export function helperHideProgressSpinner() {
  window.document.getElementById('loader').style.display = 'none';
}

export function helperDownloadFile(blobData, applicationType, downloadName) {
  const blob = new window.Blob([blobData], {
    type: applicationType,
  });
  const url = window.URL.createObjectURL(blob);
  const link = window.document.createElement('a');
  link.href = url;
  link.setAttribute('download', downloadName);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
}

export function helperShowInImageMagnifierPopup(filepath) {
  if (! window.navigator.onLine) {
    console.error('No internet connection available!');
    return;
  }
  const imageMagnifierPopup = window.jQuery('#image-magnifier-popup');
  imageMagnifierPopup.find('img').attr('src', process.env.REACT_APP_FRONTEND_URL + 'storage/' + filepath);
  imageMagnifierPopup.modal('show');
}

export function helperDefaultLang() {
  const savedLang = window.localStorage.getItem('user-lang');
  if (savedLang) {
    return savedLang;
  }
  var userLang = window.navigator.language || window.navigator.userLanguage;
  userLang = userLang.toLowerCase();
  if (userLang.indexOf('en') > -1) {
    return 'en';
  }
  if (userLang.indexOf('ru') > -1) {
    return 'ru';
  }
  return 'uz';
}

// https://goenning.net/blog/how-to-retry-dynamic-import-with-react-lazy/
export function helperRetryImport(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }
          // Passing on "reject" is the important part
          helperRetryImport(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export function helperCurrentLang() {
  return window.location.pathname.split('/')[1] || 'uz';
}

export function helperIsPwaInstalled() {
  const result = (window.navigator && window.navigator.standalone)
    ||
    window.matchMedia('(display-mode: standalone)').matches
    ||
    window.document.referrer.indexOf('android-app://') > -1;

  if (result === true) {
    console.log('app was installed');
  }

  return result;
}

export function helperGetMediaBreakpoint() {
  return window.innerWidth;
}

export function helperDebounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

export function helperCopyrightYear() {
  const currentYear = (new Date()).getFullYear();
  const launchedYear = process.env.REACT_APP_LAUNCHED_YEAR;
  const copyrightYear = (currentYear > launchedYear) ? `${launchedYear}-${currentYear}` : currentYear;
  return copyrightYear;
}
