import { Suspense, lazy, useEffect } from 'react';
import { connect } from 'react-redux';
import AppSwitch from '../AppSwitch';
import { Switch, Route, Redirect } from "react-router-dom";
import RedirectFromShortUrl from '../components/bookings/RedirectFromShortUrl';
import { ErrorBoundary } from 'react-error-boundary';
import messages from '../lang/messages';
import {
  helperDefaultLang,
  helperRetryImport,
  helperCurrentLang,
  helperDebounce,
  helperGetMediaBreakpoint,
} from '../helpers/index';
import SuspenseFallback from '../components/SuspenseFallback';
import { setIsPwaInstalled, setMediaBreakpoint } from '../actions/index';
const Modal = lazy(() => helperRetryImport(() => import('../components/Modal')));
const CookieNotification = lazy(() => helperRetryImport(() => import('../components/CookieNotification')));
const InstallPWAPrompt = lazy(() => helperRetryImport(() => import('../components/InstallPWAPrompt')));

function App(props) {
  useEffect(() => {
    window.addEventListener('DOMContentLoaded', () => {
      window.matchMedia('(display-mode: standalone)').addListener((evt) => {
        if (evt.matches) {
          props.setIsPwaInstalled(true);
        }
      })
    });

    return checkForCodebaseUpdates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedHandleWindowResize = helperDebounce(function handleResize() {
    props.setMediaBreakpoint(helperGetMediaBreakpoint());
  }, 250);

  useEffect(() => {
    window.addEventListener('resize', debouncedHandleWindowResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleWindowResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function checkForCodebaseUpdates() {
    // periodically (every 10 minutes) check for codebase updates
    const intervalId = setInterval(function () {
      if (window.navigator && window.navigator.onLine) {
        console.log('checking for codebase updates...');
        window.axios
          .get(`${ window.location.origin }/v.json?t=${ Date.now() }`)
          .then((result) => {
            if (result.data.val !== window.document.querySelector('meta[name="v"]').getAttribute('content')) {
              window.document.dispatchEvent(new Event('codebase_updated'));
            } else {
              console.log('codebase is uptodate!');
            }
          });
      } else {
        console.error('no internet connection, aborting the check for codebase updates');
      }
    }, 600000);

    return () => {
      clearInterval(intervalId);
    };
  }

  function reloadAfterUpdate() {
    if ('serviceWorker' in window.navigator) {
      window
        .navigator
        .serviceWorker
        .ready
        .then((registration) => {
          if (registration.waiting && registration.waiting.state === 'installed') {
            registration.waiting.postMessage({
              type: 'skip_waiting',
            });
          }
        });
    }
  }

  const defaultLang = helperDefaultLang();

  return(
    <ErrorBoundary FallbackComponent={ Fallback } onError={ logError }>
      <Switch>
        <Route exact={ true } path="/b/:bookingId/:bookingCode" component={ RedirectFromShortUrl }/>
        <Route path="/:lang" component={ AppSwitch } />
        <Redirect to={ `/${defaultLang}` } />
      </Switch>
      <Suspense fallback={ <SuspenseFallback /> }>
        <Modal modalId="reload-modal"
          isConfirm={ true }
          okButtonText="refresh-now"
          cancelButtonText="not-now"
          confirmCallback={ reloadAfterUpdate }
        />
        <Modal />
        {
          (window.localStorage.getItem('agreed-to-cookies') === 'yes') ?
            null
            :
            <CookieNotification />
        }
        {
          (props.isPwaInstalled) ?
            null
            :
            <InstallPWAPrompt />
        }
      </Suspense>
    </ErrorBoundary>
  );
}

function Fallback({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  const lang = helperCurrentLang();
  return (
    <div role="alert" className="p-4 text-center">
      <p>{ messages[lang]['unhandled_error_hint'] }:</p>
      <pre className="my-4 text-danger">{ error.message }</pre>
      <button type="button"
        onClick={ () => window.location.reload() }
        className="genric-btn info"
      >
        { messages[lang]['reload'] }
      </button>
      <p className="mt-5">{ messages[lang]['contacts'] }:</p>
      <h6>
        <a href={ `https://t.me/${ process.env.REACT_APP_TELEGRAM_SUPPORT_ACCOUNT_USERNAME }` } target="_blank" rel="noreferrer">
          @{ process.env.REACT_APP_TELEGRAM_SUPPORT_ACCOUNT_USERNAME }
        </a>
      </h6>
      <h6>
        <a href={ `mailto:${ process.env.REACT_APP_SUPPORT_EMAIL }` }>
          { process.env.REACT_APP_SUPPORT_EMAIL }
        </a>
      </h6>
    </div>
  );
}

const logError = (error, info) => {
  window.axios
    .post('/2f5c0689-e354-4fe5-bf35-50b8bd2eb418/log-frontend-error', {
      message: `${ error.message }: ${ info.componentStack }`,
    });
};


const mapStateToProps = (state) => {
  return {
    isPwaInstalled: state.isPwaInstalled,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIsPwaInstalled: (flag) => dispatch(setIsPwaInstalled(flag)),
    setMediaBreakpoint: (mediaBreakpoint) => dispatch(setMediaBreakpoint(mediaBreakpoint)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
